body {
  font-family: 'Roboto', sans-serif;
  background-color: #edf0f5;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

button:hover {
  outline: none;
}

button:focus {
  outline: none;
}

input:focus {
  box-shadow: none !important;
  border-color: #f0f4f7 !important;
}

#wrapper {
  display: inherit !important;
}

.containerRight {
  float: right;
  width: calc(100% - 225px);
}

.blueColor {
  color: #0091eb;
}

/**  SideBar  **/
.sidebar {
  width: 225px;
  position: fixed;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2b364a;
}

.sidebar button {
  text-align: left;
  width: 100% !important;
}

.sidebar li {
  float: left;
  width: 100%;
  display: inline-block;
  cursor: pointer;
}
.logoContent img{
  width:85%;
}
.logo img{
  width:90%;
}
.sidebar .nav-item .nav-link {
  padding: 1rem;
}

.sidebar li.nav-item {
  border-left: 4px solid #2b364a;
}

li.nav-item.active {
  background-color: #252e3f;
  border-left: 4px solid #0091eb;
}

li.nav-item:active {
  background-color: #252e3f;
  border-left: 4px solid #0091eb;
}

.sidebar .nav-item .nav-link {
  color: rgba(255, 255, 255, .5);
}

.navbar-nav .nav-item.active .nav-link {
  color: #fff;
}

.logo {
  float: left;
  text-align: left;
  line-height: 71px;
  height: 71px;
}

.logo button {
  font-size: 20px !important;
  color: #ffffff !important;
  padding-left: 20px;
}

.nav-item .nav-link {
  line-height: 26px;
}

.nav-item .nav-link img {
  float: left;
  width: 26px;
  margin-right: 10px;
}

.logOutBottom {
  position: absolute;
  bottom: 20px;
}

/** Buttons  **/
.btn {
  min-width: 150px;
  font-size: 15px;
}

.blueBtn {
  background-color: #0091eb;
  border-color: #0091eb;
  color: #ffffff !important;
}

.redBtn {
  background-color: #f90d4f;
  border-color: #f90d4f;
  color: #ffffff !important;
}

.darkBlueBtn {
  background-color: #2b364a;
  border-color: #2b364a;
  color: #ffffff !important;
}

.outLineBtn {
  border-color: #2b364a;
  background-color: transparent;
  color: #2b364a;
}

.btn:hover {
  border-color: #2b364a;
  background-color: transparent;
  color: #2b364a !important;
  box-shadow: 0 0 5px 1px #ddd !important;
}

/** Wrapper  **/
.navbar-brand {
  font-size: 15px;
}

ol.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
}

.user {
  margin: 0;
}

.user li {
  float: left;
  display: inline-block;
  line-height: 36px;
  padding: 0 10px;
  border-right: 1px solid #eaeaea;
  font-size: 15px;
}

.user li:last-child {
  border-right: none;
}

.card {
  border: none;
}

/** Home  **/

.previreDetail span {
  color: #a6a6a6;
  font-size: 15px;
  font-weight: normal;
}

.card-header {
  line-height: 36px;
}

.card-footer {
  line-height: 36px;
}

.searchForm.btn {
  min-width: auto;
  background-color: #a9b0b6;
  border-color: #a9b0b6;
  cursor: default !important;
}

.searchForm.btn:hover {
  box-shadow: none !important;
}

.form-control {
  box-shadow: none;
  border-color: #f0f4f7;
  background-color: #f0f4f7 !important;
  font-size: 14px !important;
}

thead tr th {
  font-weight: 400;
  color: #282828;
}

tbody tr td {
  color: #003564;
  font-size: 13px;
}

tbody tr th {
  float: left;
  padding-left: 0 !important;
}

/** Login Form  **/

.loginForm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.loginCard {
  width: 360px;
  box-shadow: 0 0 5px 1px rgba(234, 234, 234, 0.4588235294117647);
  background-color: #ffffff;
}

.logoContent {
  text-align: center;
  margin-bottom: 30px;
}

.logoText {
  text-align: left;
  margin-top: 30px;
}

.logoText h2 {
  font-weight: 300;
  font-size: 26px;
  margin-bottom: 10px;
  color: #2b364a;
}

.logoText span {
  color: #003564;
  font-size: 15px;
}

.backToLogin {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #0091eb;
  margin-top: 10px;
}

.input-group {
  width: 100%;
}

.forgotBtn {
  line-height: 40px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.navbar {
  background-color: #ffffff;
}

.bg-light {
  background-color: #ffffff;
}

.withSearch {
  display: flex;
  justify-content: space-between;
}

.searchInputGroup {
  display: flex;
  flex-direction: row;
}

.fixOnTop {
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 225px);
  z-index: 99;
  box-shadow: 1px 2px 4px 1px rgba(221, 221, 221, 0.4196078431372549);
}

#content-wrapper {
  margin-top: 72px !important;
}

tbody tr th {
  padding-left: 5px !important;
}

.withLabel tbody tr td {
  padding: 0 !important;
}

.homeTable tbody tr td {
  padding: 0 !important;
}

.notificationTable tbody tr td {
  padding: 0 !important;
}

tbody tr td label {
  display: block;
  padding: .75rem;
  margin-bottom: 0;
}

.card-footer input[type=checkbox] {
  margin-left: 5px !important;
}

.blueBtn:hover {
  background-color: #007bc7 !important;
  border-color: #007bc7 !important;
  color: #ffffff !important;
}

.redBtn:hover {
  background-color: #b30334;
  border-color: #b30334 !important;
  color: #ffffff !important;
}

.darkBlueBtn:hover {
  background-color: #324363;
  border-color: #324363;
  color: #fff !important;
}

.blueBtn:active {
  background-color: #007bc7 !important;
  border-color: #007bc7 !important;
  color: #ffffff !important;
}

.redBtn:active {
  background-color: #b30334;
  border-color: #b30334 !important;
  color: #ffffff !important;
}

.darkBlueBtn:active {
  background-color: #324363;
  border-color: #324363;
  color: #fff !important;
}

.table td, .table th {
  vertical-align: inherit !important;
}

.nav-link:focus, .nav-link:hover {
  color: #fff !important;
}

table {
  text-align: left;
}

table .topBorder {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6) !important;
}

table .buttomBorder {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6) !important;
}

/*    Registration  */

.fullWidth {
  width: 100% !important;
}

.registrationForm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 100%;
  padding-top: 90px;
  background-color: #ffffff;
}

.registrationStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: calc(100% - 70px);
  background-color: #ffffff;
}

.registrationStatus h4 {
  font-size: 18px;
}

.readableInputs span {
  font-size: 14px;
}

.readableInputs .inputText {
  background-color: #f0f4f7;
  border-radius: 5px;
  width: 100%;
  line-height: 40px;
  padding: 0 10px;
}

.content {
  text-align: left;
  margin-bottom: 15px;
  font-size: 14px;
}

.imageBox {
  border-radius: 5px;
  border: 1px solid #f0f4f7;
}

.checkLabel {
  font-size: 13px;
}

.site {
  color: #0091eb !important;
  font-weight: 500;
}

.decoration.site {
  border-bottom: 1px solid #0091eb;
}

.pagination a {
  color: #007bff !important;
}

.pagination span {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000000;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.SelectPaginatiom {
  line-height: 38px !important;
}

#select {
  border-radius: 0;
  height: 38px;
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
  padding: 0 .5em;
  padding-right: 1.5em;
  border: 1px solid #dee2e6
}

select:focus {
  outline: none;
}

.logOutIcon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.noRequest {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registrationForm .card {
  min-height: 460px;
}

.registerCardText {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 460px;
  flex-direction: column;
}

.registerCardText h4 {
  font-size: 16px;
  text-align: center;
}

thead tr {
  box-shadow: 0 0 1px #ddd;
}

.tableOutBorder {
  border: #dddddd 1px solid;
  border-top: none;
  border-bottom: none;
  margin-bottom: 0;
}

.border {
  border: 1px solid #dddddd;
}

.uploadBtn {
  border: 1px solid #828384;
  color: #828384;
}

.p-10 {
  padding: 10px;
}

.color-gray {
  color: #828384;
}

.loaderPosition {
  position: absolute;
  left: 200px;
  top: 0;
}

.warningText {
  font-size: 13px;
  line-height: 14px;
  float: left;
  width: 100%;
  margin: 5px 0;
}

.errorText {
  text-align: center;
  padding: 0 10px;
}

.readableInputs input[type=checkbox]{
  width: 26px !important;
  height: 13px;
  float: left;
}

@media screen and (max-width: 768px) {
  .registrationForm {
    display: inherit !important;
    padding-top: 90px;
  }
  .registrationForm .loginCard {
    margin: auto !important;
  }
}
